import { CookiePopup, Footer, RedLoading, SEO } from 'components';
import { motion } from 'framer-motion';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import { InStoreExperienceActions } from 'modules/inStore';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cta, intro, spacing, utils } from 'style';

const IndexPage: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(InStoreExperienceActions.StopInStoreExperience());
  }, [dispatch]);

  return (
    <PageTransition>
      <SEO
        title={intl.formatMessage({ id: 'indexPageSeo' })}
        url="https://find-your-fit.eu/en/"
        urlEN="https://find-your-fit.eu/en/"
        urlDE="https://find-your-fit.eu/de/"
        urlIT="https://find-your-fit.eu/it/"
        urlNL="https://find-your-fit.eu/nl/"
        urlFR="https://find-your-fit.eu/fr/"
      />
      <RedLoading />

      <section css={[intro.root, utils.wrapper]}>
        <div css={intro.carousel}>
          <motion.h1
            css={[intro.title, intro.carouselTitle]}
            animate={{ y: [30, 0], opacity: [0, 1] }}
            transition={{ duration: 2, delay: 3, ease: 'circOut' }}
          >
            <FormattedMessage id="introTitlePartOne" />
            <br />
            <span css={utils.fontFamilySansAlt}>
              <FormattedMessage id="introTitlePartTwo" />
              <br />
            </span>{' '}
            <FormattedMessage id="introTitlePartThree" />
          </motion.h1>
          <motion.div
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, delay: 3.5, ease: 'circOut' }}
          >
            <motion.div
              animate={{
                opacity: [0, 1, 0],
                transition: {
                  ease: 'easeIn',
                  duration: 0,
                  delay: 3.5,
                },
              }}
              css={intro.carouselItem}
            >
              <img
                src={require('assets/images/carousel/nike-bra-carousel-1.jpg')}
                alt=""
              />
            </motion.div>
            <motion.div
              animate={{
                opacity: [0, 1, 0],
                transition: {
                  ease: 'easeIn',
                  duration: 0,
                  delay: 4,
                },
              }}
              css={intro.carouselItem}
            >
              <img
                src={require('assets/images/carousel/nike-bra-carousel-2.jpg')}
                alt=""
              />
            </motion.div>
            <motion.div
              animate={{
                opacity: [0, 1, 0],
                transition: {
                  ease: 'easeIn',
                  duration: 0,
                  delay: 4.5,
                },
              }}
              css={intro.carouselItem}
            >
              <img
                src={require('assets/images/carousel/nike-bra-carousel-3.jpg')}
                alt=""
              />
            </motion.div>
            <motion.div
              animate={{
                opacity: [0, 1, 0],
                transition: {
                  ease: 'easeIn',
                  duration: 0,
                  delay: 5,
                },
              }}
              css={intro.carouselItem}
            >
              <img
                src={require('assets/images/carousel/nike-bra-carousel-4.jpg')}
                alt=""
              />
            </motion.div>
            <motion.div
              animate={{
                opacity: [0, 1, 0],
                transition: {
                  ease: 'easeIn',
                  duration: 0,
                  delay: 5.5,
                },
              }}
              css={intro.carouselItem}
            >
              <img
                src={require('assets/images/carousel/nike-bra-carousel-5.jpg')}
                alt=""
              />
            </motion.div>
            <motion.div
              animate={{
                opacity: [0, 1],
                transition: {
                  ease: 'easeIn',
                  duration: 0,
                  delay: 6,
                },
              }}
              css={intro.carouselItem}
            >
              <img
                src={require('assets/images/carousel/nike-bra-carousel-6.jpg')}
                alt=""
              />
            </motion.div>
          </motion.div>
        </div>
        <div css={intro.content}>
          <motion.div
            animate={{ y: [30, 0], opacity: [0, 1] }}
            transition={{ duration: 1.5, delay: 4, ease: 'circOut' }}
          >
            <p
              css={[
                utils.typeLarge,
                utils.fontFamilySansAlt,
                spacing.bottom.large,
              ]}
            >
              <FormattedMessage id="introDescriptionPartOne" />
              <span css={utils.fontFamilySans}>
                {' '}
                <FormattedMessage id="introDescriptionPartTwo" />{' '}
              </span>
              <FormattedMessage id="introDescriptionPartThree" />
            </p>
          </motion.div>
          <motion.div
            animate={{ y: [30, 0], opacity: [0, 1] }}
            transition={{ duration: 1.5, delay: 4.25, ease: 'circOut' }}
          >
            <p css={[utils.typeLarge, utils.fontFamilySansAlt]}>
              <FormattedMessage id="introFooterPartOne" />
              <span css={utils.fontFamilySans}>
                <FormattedMessage id="introFooterPartTwo" />
              </span>{' '}
              <FormattedMessage id="introFooterPartThree" />{' '}
              <span css={utils.fontFamilySans}>
                <FormattedMessage id="introFooterPartFour" />
              </span>
            </p>
          </motion.div>
        </div>

        <Link to="/find-your-fit" css={cta.button}>
          <FormattedMessage id="introCta" />
        </Link>
      </section>
      <CookiePopup />
      <Footer />
    </PageTransition>
  );
};

export default IndexPage;
